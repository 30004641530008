.ProgressItem {
    // display: flex;
    // margin-top: 8px;
    margin-left: 16px;
}

.ProgressItem:first-child {
    margin-left: 0;
}

.ProgressItemWrapper {
    display: flex;
    color: var(--true-white-color);
}

.ProgressItemReceived {
    display: flex;
}

.TextReceived {
    // margin-left: 12px;
    // margin-top: 5px;
    margin-left: 4px;
    align-self: flex-end;
    margin-bottom: 2px;
    opacity: 0.6;
}

.chevrons {
    animation: 2s chevronAnim infinite;
    width: 16px;
    margin-right:  4px;
}

@keyframes chevronAnim {
    0% {opacity: 0.5; scale: 0.85}
    50% {opacity: 1; scale: 1}
    100% {opacity: 0.5; scale: 0.85}
}