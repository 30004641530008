.ProgressContainer {
    margin-top: 12px;
    margin-left: 24px;
    display: flex;
    width: 100%;
    align-items: center;
}

.ProgressItem {
    // display: flex;
    // margin-top: 12px;
    margin-left: 12px;
    // justify-self:left;
}

.ProgressItemWrapper {
    // width: 200px;
    display: flex;
}

.ProgressCount {
    margin-top: -5px;
    margin-right: 5px;
}

.ProgressGoal {
    // margin-left: 5px;
}

.RoundProgress {
    margin-left: 24px;
}

.RoundProgress:first-child {
    margin-left: 0px;
}