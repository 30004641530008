.Container {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--true-white-color);
    z-index: 2;
}

.Fixed {
    position: fixed;
    padding-bottom: 10px;
}

.Header {
    margin-left: 12px;
    margin-right: auto;
    margin-top: 15px;
    font: var(--font-l);
    font-weight: 900;
}

.Counter {
    margin-right: 16px;
    margin-top: 14px;
}

.CloseTextButton {
    margin-left: 16px;
    margin-top: 12px;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}


@media (max-width: 364px) {
    .Title {
        font: var(--font-m);
        font-weight: 900;
    }
    .Counter {
        margin-top: 16px;
        font: var(--font-m);
        font-weight: 900;
    }
}

@media (min-width: 1023px) {
    .Container {
        width: 680px;
    }
}