.SingleCollectionPage {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;

    // background-color: black;
}

.Header {
    opacity: 0;
    width: 100%;
    height: 72px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    background-color: var(--true-white-color);
    z-index: 5;
}

.ButtonHeader {
    width: 100%;
    height: 72px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 6;
}

.HeaderWrapper {
    width: 100%;
    display: flex;
    // justify-content: center;
    margin-left: 76px;
    align-items: center;
}

.Goal {
    margin-left: 8px;
    width: 32px;
}

.Share {
    width: 24px;
    height: 24px;
    pointer-events: none;
}

.HeaderButton {
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
    margin-top: 12px;
    margin-left: 16px;
}
.HeaderButton:nth-child(2) {
    margin-left: 0px;
    margin-right: 16px;
}

.Background {
    animation: showBackground 0.5s;
    position: fixed;
    width: 100%;
    height: 240px;
    z-index: 0;
    object-fit: cover;
    // background: linear-gradient(180deg, rgba(91, 196, 112, 0.00) 0%, #5BC470 100%);
}

.Avatar {
    // margin-top: -88px;
    top: -60px;
    // top: 13vh;
    left: calc(50% - 47px);
    position: absolute;
    width: 94px;
    height: 94px;
    border-radius: 40px;
    border: 4px solid #fff;
    object-fit: cover;
    z-index: 3;
}

.BackgroundGradient {
    animation: animGradient 3s infinite;
    animation: showBackground 5s ease;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 240px;
    background: linear-gradient(
        180deg,
        rgba(91, 196, 112, 0) 30%,
        var(--soft-green-color) 100%
    );
}

@keyframes animGradient {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

.Wrapper {
    animation: showContainer 0.5s;
    position: relative;
    margin-top: 20vh;
}

.Container {
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;
    // height: 100%;
    // margin-top: 20vh;
    background-color: var(--true-white-color);
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MainText {
    display: flex;
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 42px;
}

.MainDescr {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
}

.Popup {
    width: 100%;
    margin-top: 24px;
}

.PopupUpper {
    width: 96%;
    margin-top: 24px;
}

.PopupContainer {
    display: flex;
    justify-content: space-between;
}

.offer {
    position: relative;
    z-index: 5;
}

.PopupText {
    // width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.PopupCup {
    width: 32px;
    margin-right: 4px;
}

.Button {
    margin-top: 24px;
    width: 100%;
    margin-bottom: 6px;
}

.ButtonUpper {
    margin-top: 24px;
    width: 96%;
    margin-bottom: 6px;
}

.AchieveText {
    margin-top: 40px;
    margin-left: 16px;
    align-self: flex-start;
}

.ProgressContainer {
    margin-top: 12px;
    margin-left: 24px;
    display: flex;
    width: 100%;
    align-items: center;
}

.ProgressItem {
    // display: flex;
    // margin-top: 12px;
    margin-left: 12px;
    // justify-self:left;
}

.ProgressItemWrapper {
    // width: 200px;
    display: flex;
}

.ProgressCount {
    margin-top: -5px;
    margin-right: 5px;
}

.ProgressGoal {
    // margin-left: 5px;
}

.RoundProgress {
    margin-left: 24px;
}

.RoundProgress:first-child {
    margin-left: 0px;
}

.Footer {
    position: relative;
    background-color: var(--true-white-color);
    // padding-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
}

.FooterText {
    background-color: white;
    position: relative;
    z-index: 3;
    padding-top: 114px;
    width: 98%;
}

.FooterButton {
    margin-top: 24px;
    width: 94%;
    margin-bottom: 80px;
}

.ButtonWrapper {
    margin-left: 24px;
    // margin-right: auto;
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: space-between;
}

.ButtonText {
    width: 100%;
    margin-left: -24px;
}

.Icon {
    width: 32px;
    pointer-events: none;
}

.footerTextContainer {
    width: 100%;
}

.BannerContainer {
    display: flex;
    width: 100%;
    margin-left: 16px;
    margin-right: 12px;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}

.BannerButton {
    width: 108px;
    height: 48px;
}

.BannerText {
    width: 100%;
}

.MarginOffer {
    margin-bottom: 100px;
}

.progressWrapper {
    width: calc(100% - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress {
    position: relative;
    margin-top: 24px;
    width: 100%;
    height: 45px;
    // background-color: var(--soft-gray-color);
    border-radius: 20px;
    border: 2px solid var(--true-black-color);
}

.progressBar {
    // width: 75%;
    // overflow: hidden;
    margin-top: -33px;
    height: 100%;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    // border-radius: 20px;
    background-color: var(--soft-green-color);
    border-right: 2px solid var(--true-black-color);
}

// .progressBarWrapper {
//     height: 100%;
//     animation: animProgress 0.5s
// }

.progressBarRadius {
    border-radius: 18px;
    // border-right: none;
}

.progressBarDisabled {
    opacity: 0;
}

.progressText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.progressTextItem {
    margin-left: 8px;
}

.progressLevels {
    margin-top: 10px;
    display: flex;
    width: calc(100% - 24px);
    justify-content: space-between;
}

.levelText {
    display: block;
}

.newLevel {
    animation: newLevelAnim 1.3s 0.15s;
}

.ContainerGrid {
    position: relative;
    animation: show 0.5s;
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: var(--true-white-color);
    z-index: 3;
}

.Grid {
    // animation: show 0.5s;
    position: relative;
    z-index: 3;
    padding-top: 24px;
    width: 95%;
    display: grid;
    grid-template-columns: 44.5vw 44.5vw;
    grid-template-rows: 36vh;
    gap: 6px 5%;
    grid-auto-rows: 36vh;
    grid-auto-columns: 44.5vw 44.5vw;
    background-color: var(--true-white-color);
    // touch-action: none;
    // touch-action: pan-y; //выключение всех тач экшнов кроме пролистывания по y
}

// @keyframes showContainer2 {
//     from {transform: translateY(0px) }
//     to {transform: translateY(0px) }
// }

.Confetti {
    position: relative;
    z-index: 5;
}

@keyframes show {
    from {
        transform: translateY(80px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes newLevelAnim {
    0% {
        scale: 1;
    }
    50% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@keyframes animProgress {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

@keyframes showBackground {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes showContainer {
    from {
        transform: translateY(80px);
    }
    to {
        transform: translateY(0px);
    }
}

@media (min-width: 1023px) {
    .Header {
        width: 680px;
    }

    .ButtonHeader {
        width: 680px;
    }

    .BackgroundGradient {
        width: 679px;
    }

    .Background {
        width: 679px;
    }

    .Grid {
        width: 680px;
        grid-template-columns: 44.5% 44.5%;
        grid-template-rows: 430px;
        grid-auto-rows: 430px;
        grid-auto-columns: 44.5% 44.5%;
        justify-content: center;
    }

    .Wrapper {
        margin-top: 200px;
    }
}
