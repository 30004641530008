.Button {
    transition: all 0.05s linear;
    width: 80px;
    height: 80px;
    border-radius: 30px;
    border: solid var(--true-black-color) 2px;
    box-shadow: 3px 3px var(--true-black-color);
    margin-left: 12px;
    // opacity: 0;
    user-select: none;
}

.Button:active {
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
    // background-color:red;
}

.Active {
    transition: all 0.2s linear;
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
}

.Reject {
    background-color: var(--soft-gray-color);
}

.Want {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--soft-orange-color);
    width: 45%;
}

.Accept {
    background-color: var(--soft-green-color);
}

.Collections {
    background-color: var(--soft-green-color);
}

.Speak { 
    background-color: var(--soft-blue-color);
}


.Icon {
    width: 32px;
    pointer-events: none;
}

.Text {
    color: var(--true-black-color);
    font: var(--font-m);
    font-weight: 600;
    margin-left: 10px;
}

.Visible {
    opacity: 1;
}

.ActiveSpeak {
    filter: invert(50%) sepia(56%) saturate(2933%) hue-rotate(195deg) brightness(93%) contrast(96%);
}

@media (max-height: 568px) {
    .Button {
        width: 70px;
        height: 70px;
        border-radius: 30px;
    }
    .Want {
        width: 45%;
    }
}
