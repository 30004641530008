.Round {
    display: flex;
    justify-content: center;
    bottom: 12px;
    right: 12px;
    position: absolute;
    width: 44px;
    height: 44px;
    border: solid var(--true-black-color) 2px;
    border-radius: 60px;
    background-color: var(--soft-green-color);
    box-shadow: 3px 5px 0px var(--true-black-color);
    z-index: 1;
}

.Round:active {
    transition: all 0.1s ease-in-out;
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
}

.wanted {
    background-color: var(--soft-orange-color);
}

.search {
    background-color: var(--soft-blue-color);
}

.rejected {
    background-color: var(--soft-gray-color);
}

.disabled {
    pointer-events: none;
}

.readonly {
    box-shadow: none;
    pointer-events: none;
}

.Icon {
    margin-top: 10px;
    width: 24px;
    height: 24px;
    pointer-events: none;
}

.static {
    position:static;
    box-shadow: none;
}

@media (max-width: 320px) {
    .Round {
        left: 64%;
        width: 36px;
        height: 36px;
    }
}

@media (max-height: 568px) {
    .Round {
        top: 72%;
        left: 64%;
    }
    
}