.Button {
    transition: all 0.05s linear;
    width: 84%;
    height: 80px;
    border: 2px solid var(--true-black-color);
    border-radius: 30px;
    background-color: var(--soft-green-color);
    box-shadow: 3px 5px 0px 0px var(--true-black-color);
    padding: 0;
}

.Button:active {
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
}

.Profile {
    // width: 31%;
    height: 90px;
    border-radius: 24px;
}

.Container {
    display: flex;
    justify-content: space-between;
}

.Icon {
    margin-top: 4px;
    width: 24px;
    height: 24px;
    pointer-events: none;
}

.IconWrapperGreen {
    border-radius: 12px;
    margin-left: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--soft-green-color);
}

.IconWrapperBlue {
    border-radius: 12px;
    margin-left: 16px;
    width: 32px;
    height: 32px;
    background-color: #a9fcf7;
}

.Counter {
    margin-right: 16px;
    font-size: 28px;
}

.ButtonTitle {
    margin-left: 16px;
    margin-top: 8px;
}

.Text {
    color: var(--true-black-color);
    font: var(--font-m);
    font-weight: 600;
}

.Children {
    align-items: center;
    display: flex;
    justify-content: center;
}

.Yellow {
    background-color: var(--soft-yellow-color);
}

.Blue {
    background-color: var(--soft-blue-color);
}

.Aquamarine {
    background-color: var(--aquamarine-color);
}

.Pink {
    background-color: var(--soft-pink-color);
}

.Green {
    background-color: var(--soft-green-color);
}

.Orange {
    background-color: var(--soft-orange-color);
}

.White {
    background-color: var(--true-white-color);
}
