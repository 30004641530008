.Link {
    color: var(--true-black-color);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px;
    width: 140px;
    user-select: none;
    background-color: white;
}

.Title {
    margin-top: 8px;
    font: var(--font-xss);
    font-weight: 500;
}

.Container {
    background-color: var(--soft-gray-color);
    display: flex;
    // top: 20px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    position: relative;
    // z-index: -1;
}

.ActiveContainer {
    background-color: var(--soft-blue-color);
    display: flex;
    // top: 20px;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
}

.Active {
    margin-top: 8px;
    font: var(--font-xss);
    font-weight: 700;
}

.Image {
    pointer-events: none;
    width: 24px;
    height: 24px;
}

.ActiveImage {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
}

.Disable {
    pointer-events: none;
}

.RedDot {
    transition: all 1s;
    animation: pulseDot 1s infinite;
    top: 5px;
    right: 5px;
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: var(--red-color);
    border-radius: 100%;
}

@keyframes pulseDot {
    from {opacity: 0; transform: scale(0.8)}
    to {opacity: 1;transform: scale(1.2);}
}