.FloatModal {
    width: 100vw;
    height: 90vh;
    height: 100dvh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.Content {
    animation: 0.5s show ease;
    width: 100vw;
    height: auto;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background-color: var(--true-white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.Closed {
    opacity: 0;
}

.CrossButton {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background-color: var(--soft-gray-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
}

.Cross {
    // position:absolute;
    // top: 5px;
    // right: 5px;
    width: 24px;
    filter: invert(63%) sepia(6%) saturate(502%) hue-rotate(202deg) brightness(90%) contrast(86%);
}

@keyframes show {
    from { transform: translateY(600px) }
    to {transform: translateY(0px) }
}

@media (max-width: 300px) {
    .Content {
        height: 400px;
    }
}

@media (min-width: 1023px) {
    .Content {
        width: 680px;
    }
}