.EmptyMomentPage {
    // margin-left: 10px;
    animation: 1s show ease;
    position: relative;
    // left: 42vw;
    // margin: 0;
    // padding-left: 93%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Title {
    // width: 90vw;
    text-align: center;
    // margin-top: 25%;
    font: var(--font-xl);
    font-weight: 700;
    margin-bottom: 32px;
    margin-left: 12px;
    margin-right: 12px;
}

.Text {
    width: 90vw;
    margin-top: 32px;
    margin-bottom: 32px;
}

.SecondText {
    margin-top: 16px;
    margin-bottom: 32px;
    margin-left: 12px;
    margin-right: 12px;
}

.Image {
    width: 220px;
    height: 160px;
    margin-bottom: 32px;
}

.FooterText {
    margin-top: 230px;
    color: var(--ter-black-color);
    text-align: center;
}

.Button {
    width: 90%;
}

@keyframes show {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (max-height: 520px) {
    .Title {
        font: var(--font-m);
        font-weight: 700;
    }
}
