.Container {
    margin-top: 80px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Text {
    font: var(--font-xl);
    font-weight: 700;
    text-align: center;
    width: 90%;
    margin-bottom: 32px;
}

.Button {
    width: 98%;
}