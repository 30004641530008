.CollectionPage {
    // margin-left: -93%;
    position: relative;
    overflow: auto;
    // overflow-y:visible;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    // overflow: auto;
    // max-height: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ButtonContainer {
    width: 96%;
    display: flex;
    justify-content: space-between;
}

.Header {
    margin-top: 15px;
    margin-left: 24px;
    width: 100%;
    display: flex;
    margin-bottom: 25px;
    justify-content: space-between;
    font: var(--font-l);
    font-weight: 900;
}

.ProfileContainer {
    animation: show 0.5s;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background {
    margin-top: 4px;
    width: calc(100% - 8px);
    height: 160px;
    object-fit: cover;
    border-radius: 30px;
    pointer-events: none;
}

.Avatar {
    margin-top: -88px;
    width: 120px;
    height: 120px;
    border-radius: 50px;
    border: 4px solid #fff;
    object-fit: cover;
}

.name {
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
}

.nickname {
    margin-top: 4px;
    margin-left: 16px;
    margin-right: 16px;
}

.description {
    overflow-wrap: break-word;
    margin-top: 8px;
    // width: calc(100% - 16px);
    margin-left: 16px;
    margin-right: 16px;
}

.ButtonProfile {
    margin-top: 24px;
}

.Settings {
    top: 12px;
    right: 16px;
    position: absolute;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}

.bannerWrapper {
    width: inherit;
}

.ShareButton {
    top: 12px;
    left: 16px;
    position: absolute;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}

.Share {
    pointer-events: none;
}

.Back {
    top: 12px;
    left: 16px;
    position: absolute;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
}

.BannerContainer {
    display: flex;
    width: 100%;
    // margin-left: 12px;
    margin-right: 16px;
    justify-content: space-between;
    align-items: center;
}

.BannerButton {
    margin-left: 12px;
    width: 108px;
    height: 48px;
}

.BannerText {
    // width: 80%;
    padding: 20px 12px 20px 16px;
}

.Margin {
    padding-bottom: 100px;
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
