.Cross {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 24px;
}

.Line {
    height: 2px;
    width: 20px;
    background-color: var(--sec-black-color);
    border-radius: 100px;
}

.Line:nth-child(1) {
    transform: translate3d(2px, 1px, 0px);
    rotate: 45deg;
}

.Line:nth-child(2) {
    transform: translate3d(0px, 0px, 0px);
    rotate: -45deg;
} 