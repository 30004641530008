.Container {
    // position: absolute;
    // top: 80px;
    // background-color: var(--true-white-color);
    // display: flex;
    // flex-direction: column;
    overflow: auto;
    // overflow-y:visible;
    // overflow-x: hidden;
    margin-top: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    // height: calc(100% - 80px);
}

.Grid {
    // animation: show 0.5s;
    display: grid;
    grid-template-columns: 44.5vw 44.5vw;
    grid-template-rows: 45px;
    gap: 6px 5%;
    grid-auto-rows: 45px;
    grid-auto-columns: 44.5vw 44.5vw;
    margin-top: 12px;
    // touch-action: none;
    // touch-action: pan-y; //выключение всех тач экшнов кроме пролистывания по y
}

.Manual {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 20px;
    // margin-left: 20px;
    // display: flex;
}

.ManualItem {
    display: flex;
    width: 50%;
    margin-left: 8px;
    justify-content: flex-start;
}

.ManualItem:nth-child(2) {
    margin-top: 16px;
}

.Text {
    margin-top: 12px;
    margin-left: 8px;
}

.PopupText {
    width: 90%;
    margin-left: 20px;
}

.TextTime {
    margin-top: 10px;
    // margin-bottom: 16px;
    margin-left: 10px;
    grid-column: span 2;
}

::-webkit-scrollbar {
    width: 0;
}

@keyframes show {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media (min-width: 1023px) {
    .Grid {
        // width: 680px;
        grid-template-columns: 40.5% 40.5%;
        grid-template-rows: 60px;
        grid-auto-rows: 60px;
        grid-auto-columns: 40.5% 40.5%;
        justify-content: center;
    }
}

@media (max-width: 364px) {
    .Grid {
        grid-template-rows: 30vh;
        grid-auto-rows: 30vh;
    }
    .Manual {
        font: var(--font-s);
    }
    .ManualItem {
        margin-left: 0;
    }
}

@media (max-width: 280px) {
    .Text {
        font-size: 11px;
    }
}
