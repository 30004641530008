.FloatWhale {
    margin-top: 24px;
    width: 220px;
    height: 160px;
}

.TitleTextFloat {
    margin-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
}

.TextFloat {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
}

.FloatButton {
    margin-top: auto;
    margin-bottom: 20px;
    width: 95%;
}

@media (max-width: 350px) {
    .FloatWhale {
        margin-top: 10px;
        width: 60px;
    }
    .TitleTextFloat {
        margin-top: 10px;
        font: var(--font-m);
        font-weight: 700;
    }
    .TextFloat {
        font: var(--font-s);
    }
}
