.Collection {
    position: relative;
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
    // width: auto;
    height: 120px;
    border-radius: 30px;
    border: 2px solid var(--true-black-color);
    box-shadow: 3px 5px 0px var(--true-black-color);
    z-index: 1;
}

// .Collection:nth-child(2) {
//     margin-top: 4px;
// }

// .active {
//     box-shadow: 0px 0px;
//     transform: translate3d(3px, 3px, 0px);
// }

.CollectionReceived {
    border-color: var(--green-color);
    box-shadow: 3px 5px 0px var(--green-color);
}

.Gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 28px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0,0,0, 0.40) 100%);
}

.Background {
    border-radius: 28px;
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    pointer-events: none;
}

.Cards {
    width: 120px;
}

.FlexWrapper {
    display: flex;
    align-items: center;
}

.Progress {
    margin-left: 8px;
    // margin-top: 24px;
}

.ProgressBar {
    display: flex;
    // justify-content: space-between;
}

.GoalImg {
    width: 32px;
    right: 16px;
    top: 10px;
    position: absolute;
}

.Chevron {
    right: 8px;
    top: 50px;
    position: absolute;
}

.progress {
    animation: animProgress 1s;
    overflow: hidden;
    width: 100%;
    height: 100%;
    // background-color: #DCFFA2;
    position: absolute;
    bottom: 0px;
    border-radius: 28px;
    // left: 10px;
    // border-bottom-left-radius: 70%;
    // border-top-left-radius: 5px;
    z-index: 1;
    display: flex;
    align-items: flex-end;
}

.progressBar {
   background-color: var(--soft-green-color);
   height: 3px;
}

.announceText {
    opacity: 0.6;
}

.gradientAnnounce {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0,0,0, 0.80) 100%);
}

@keyframes animProgress {
    from {
        width: 0%;
    }
    to {
        width: 100%;
    }
}

// progress {
//     background-color: greenyellow;
//     // position: absolute;
//     // bottom: -5px;
//     width: 100%;
//     height: 10px;
//     // z-index: -3;
// }