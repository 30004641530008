.NotFound {
    animation: 0.6s show ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Text {
    margin-top: 32px;
}


.Image {
    width: 100px;
    height: 100px;
}

.Button {
    margin-top: 32px;
}
