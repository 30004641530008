.FloatWhale {
    margin-top: 24px;
    width: 100px;
}

.TitleTextFloat {
    align-self: start;
    margin-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
}

.TextFloat {
    margin-top: 16px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 32px;
}

.FloatButton {
    margin-top: auto;
    margin-bottom: 24px;
    width: 95%;
}

.CloseButton {
    margin-top: 29.5px;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.ImageContainer {
    margin-top: 24px;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.Icon {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-left: 60px;
    margin-top: 60px;
    // top: 2px;
    // left: 200px;
    // top: 320px
}
.Image {
    width: 84px;
    height: 84px;
}

@media (max-width: 350px) {
    .FloatWhale {
        margin-top: 10px;
        width: 60px;
    }
    .TitleTextFloat {
        margin-top: 10px;
        font: var(--font-m);
        font-weight: 700;
    }
    .TextFloat {
        font: var(--font-s);
    }
}