.TextEditor {
    // animation: 0.5s showTextEditor ease;
    // background-color: var(--true-white-color);
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
}

.ButtonContainer {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.OpenTextButton {
    animation: showRightButton 0.2s ease-in-out;
    width: 120px;
    height: 48px;
    margin-right: 20px;
}

.CloseTextButton {
    animation: showLeftButton 0.2s ease-in-out;
    background-color: var(--true-white-color);
    width: 48px;
    height: 48px;
    margin-left: 20px;
}

.TextEditorBackground {
    animation: 0.4s showTextEditor ease-out;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    background-color: var(--true-white-color);
}

// @media screen and (max-aspect-ratio: 1/1) { 
//     .TextEditor {
//         height: 50%;
//     }
// }

.Input {
    resize: none;
    border: none;
    font: var(--font-xl);
    font-weight: 400;
    margin-top: 8px;
    height: 100%;
    width: 100%;
    overscroll-behavior-y: none;
    -webkit-overflow-scrolling: none;
      touch-action: none; //выключение оверскролла
}

.Input:focus {
    outline: none;
}

.TextAreaContainer {
    animation: showTextInput 0.4s ease-in-out;
    width: 82%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 40%;
}

.Counter {
    margin-top: 32px;
    color: var(--ter-black-color);
    font: var(--font-s);
    font-weight: 500;
    // margin-top: auto;
    // margin-left: 28px;
    align-self: start;
    justify-self: left;
}

.Warning {
    color: #EC465C;
}

@keyframes showLeftButton {
    from {transform: translateX(-100px)}
    to {transform: translateX(0px);}
}

@keyframes showRightButton {
    from {transform: translateX(100px)}
    to {transform: translateX(0px);}
}

@keyframes showTextInput {
    from {opacity: 0; transform: translateY(50px)}
    to {opacity: 1; transform: translateY(0px)}
}

@keyframes showTextEditor {
    from {opacity: 0;}
    to {opacity: 1;}
}

// @keyframes showTextEditor {
//     from {opatransform: translateY(-1500px)}
//     to {transform: translateY(0px);}
// }