.BottomButton {
    margin-top: 78px;
    margin-left: 12px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Button {
    margin-top: 32px;
}