.Banner {
    position: fixed;
    bottom: 0px;
    // min-height: 82px;
    height: auto;
    width: 100%;
    background-color: var(--soft-blue-color);
    z-index: 5;
    display: flex;
    align-items: center;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
}

.Whale {
    height: 42px;
    width: 42px;
    margin-left: 16px;
}

@media (min-width: 1023px) {
    .Banner {
        width: 680px;
    }
}
