.Deck {
    margin-left: 8px;
    width: 120px;
    height: 120px;
    position: relative;
    overflow: hidden;
}

.FirstCard {
    animation: showFirstCard 0.4s;
    position: absolute;
    rotate: -4deg;
    object-fit: cover;
    transform: translate(20px, 15px);
}

.SecondCard {
    animation: showSecondCard 0.4s;
    position: absolute;
    rotate: -8deg;
    transform: translate(10px, 10px);
    object-fit: cover;
}

@keyframes showFirstCard {
    from {transform: translate(20px,80px) }
    to {transform: translate(20px, 15px) }
}
@keyframes showSecondCard {
    from {transform: translate(10px,80px) }
    to {transform: translate(10px, 10px) }
}
