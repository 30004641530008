@import 'variables/global.scss';
@import 'themes/normal.scss';
@import 'fonts/fonts.scss';

.taglife {
  font: var(--title-l);
  width: 100%;
  margin-top: 15px;
  margin-left: 24px;
  margin-bottom: 15px;
}

.app {
  font-family: var(--font-family-main);
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100vw;
  height: 84vh;
  height: 100dvh;
  -webkit-tap-highlight-color: transparent; //выключение области клика на тлф
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: none;
  touch-action: none; //выключение оверскролла
  // touch-action: pan-down;
  background-color: white;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  overscroll-behavior-y: none;
  // background-color: white;
}

@media (min-width: 1023px) {
  .app {
    width: 680px;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
  }
}
