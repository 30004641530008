.FriendsItem {
    position: relative;
    width: 100%;
    height: 80px;
    border-radius: 30px;
    border: 2px solid var(--true-black-color);
    box-shadow: 3px 5px 0px 0px var(--true-black-color);
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.avatar { 
    margin-left: 16px;
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 20px;
}

.nameContainer {
    margin-left: 12px;

}

.nickname {
    margin-top: 2px;
}

.chevron {
    top: 28px;
    right: 16px;
    position: absolute;
    width: 24px;
}

.receivedContainer {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 50px;
}

.cup {
    width: 24px;
    margin-right: 8px;
}