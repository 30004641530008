.Container {
    // margin-left: -93%;
    animation: 0.5s show ease;
    position: relative;
    width: 98%;
    height: 100%;
    // max-height: 100%;
    display: flex;
    justify-content: center;
}

@keyframes show {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.Title {
    align-self: flex-start;
    height: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.counter {
    margin-left: 6px;
    justify-self: flex-end;
}

.logoWrapper {
    display: flex;
    align-items: center;
}

.Logo {
    align-self: flex-start;
    height: 30px;
    // width: 100%;
    margin-top: 13px;
    margin-left: 16px;
    margin-bottom: 16px;
}

.DownloadText {
    // margin-right: 16px;
}

.Download {
    height: 24px;
    pointer-events: none;
}

.DownloadWrapperImg {
    width: 40px;
    height: 40px;
    background-color: var(--soft-gray-color);
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-left: 12px;
    justify-content: center;
}

.DownloadWrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
}
