.CollectionContainer {
    margin-top: 12px;
    width: 100%;
    height: 100%;
}

.Counter {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
    height: 64px;
    align-items: center;
}

.active {
    box-shadow: 0px 0px;
    transform: translate3d(3px, 3px, 0px);
}

.Icon {
    width: 32px;
    pointer-events: none;
}

.IconBox {
    width: 24px;
}

.CounterWrapper {
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    align-items: flex-end;
}

.CollectionCounter {
    margin-left: 8px;
    margin-right: 8px;
    // width: 100%;
}

.CollectionCounter:nth-child(2) {
    margin-right: 0px;
}

.SuggestCollection {
    animation: suggestShow 0.5s;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
    // width: auto;
    height: 120px;
    border-radius: 30px;
    border: 2px solid var(--true-black-color);
}

.Background {
    border-radius: 28px;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
}

.SuggestContainer {
    position: relative;
    display: flex;
    width: 100%;
    // justify-content: space-between;
    // justify-content: space-between;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    border-radius: 28px;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.4) 100%
    );
}

.SuggestText {
    margin-left: 24px;
}

.SuggestButton {
    margin-right: 24px;
    width: 48%;
    height: 48px;
}

@keyframes suggestShow {
    from {
        opacity: 0;
        transform: translate(0px, 50px);
    }
    to {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}
