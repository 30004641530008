.Image {
    height: 160px;
    width: 220px;
    margin-top: 40%;
    margin-bottom: 32px;
}

.Text {
    text-align: center;
    width: 90%;
    // margin-top: 50%;
    // margin-left: 24px;
    font: var(--font-xl);
    font-weight: 700;
    margin-bottom: 32px;
}

.Button {
    color: black;
    font: var(--font-m);
    font-weight: 700;
    width: 80vw;
    height: 80px;
    border: 2px solid black;
    border-radius: 30px;
    background-color: #ceff1a;
    box-shadow: 3px 5px 0px 0px black;
}

.SecondaryText {
    margin-top: 24px;
    text-align: center;
    color: #8f8f9c;
    margin-left: 16px;
    margin-right: 16px;
}

.href {
    text-decoration: none;
    color: #3a86e9;
}

.Logo {
    align-self: flex-start;
    margin-left: 16px;
    margin-top: 13px;
    width: 110px;
    height: 34px;
}
