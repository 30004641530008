.FloatModal {
    width: 100vw;
    height: 90vh;
    height: 100dvh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
}

.Content {
    position: relative;
    // animation: 0.5s showFloat;
    width: 100vw;
    height: 98%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    background-color: var(--true-white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.magnifier {
    width: 24px;
    position: absolute;
    top: 33px;
    left: 28px;
    pointer-events: none;
}

.inputSearch {
    border: 2px solid var(--true-black-color);
    border-radius: 20px;
    width: calc(100% - 152px);
    height: 60px;
    padding-left: 20px;
    font: var(--font-m);
    font-weight: 500;
    padding-top: 0px;
    padding-bottom: 0px;
    outline: none;
    margin-left: 12px;
}

.SearchContainer {
    margin-top: 12px;
    display: flex;
    width: 100%;
    // justify-content: space-between;
}

.CrossButton {
    animation: showRightButton 0.2s ease-in-out;
    margin-left: 12px;
    margin-right: 12px;
    width: 60px;
    height: 60px;
    border-radius: 20px;
    background-color: var(--soft-gray-color);
}

.ImageArray {
    // animation: show 0.5s;
    margin-top: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 196px;
    gap: 1px 1px;
    grid-auto-rows: 196px;
    grid-auto-columns: 50% 50%;
    background-color: var(--true-white-color);
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    // touch-action: none;
    // touch-action: pan-y; //выключение всех тач экшнов кроме пролистывания по y
}

.ImageTenor {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.clearIcon {
    position: absolute;
    right: 100px;
    top: 32px;
    width: 24px;
}

@media (min-width: 1023px) {
    .Content {
        width: 680px;
    }

}