.EmptyCollectionPage {
    // margin-left: 10px;
    animation: 1s show ease;
    position: relative;
    // left: 42vw;
    // margin: 0;
    // padding-left: 93%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Image {
    height: 160px;
    width: 220px;
    margin-bottom: 32px;
    pointer-events: none;
}

.Title {
    // width: 80%;
    margin-left: 12px;
    margin-right: 12px;
    text-align: center;
    font: var(--font-xl);
    font-weight: 700;
    margin-bottom: 16px;
}

.Second {
    margin: 0 12px;
}

.Text {
    text-align: left;
    width: 92vw;
    margin-top: 32px;
    font: var(--font-m);
}

.FooterText {
    margin-left: 13%;
    width: 68%;
    margin-top: 30vh;
    text-align: center;
    font: var(--font-m);
    color: var(--ter-black-color);
}

.Button {
    margin-top: 32px;
    width: 75%;
}

.Arrow {
    transform: rotate(135deg);
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
