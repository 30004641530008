.ButtonContainer {
    width: 100%;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ButtonWrapper {
    margin-left: 12px;
    margin-right: 12px;
    width: 96%;
    display: flex;
    justify-content: space-between;
}


.ButtonProfile {
    margin-left: 12px;
    // margin-bottom: 24px;
    // width: 96%;
}

.ButtonProfile:first-child {
    margin-left: 0px;
}

.ButtonProfileAdded {
    // margin-left: 12px;
    background-color: var(--soft-blue-color);
    width: 96%;
    margin-bottom: 24px;
    height: 48px;
    margin-left: 12px;
    margin-right: 12px;
}

.ButtonProfileNotAdded {
    // margin-left: 12px;
    width: 96%;
    margin-bottom: 24px;
    height: 48px;
    margin-left: 12px;
    margin-right: 12px;
}

.Container {
    display: flex;
    align-items: center;
}

.Icon {
    width: 32px;
    pointer-events: none;
}

.IconFriend {
    // margin-top: 4px;
    pointer-events: none;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.ChooserWrapper {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.Chooser {
    margin-right: 12px;
    width: 100%;
    // background-color: none;
    height: 52px;
    font: var(--font-m);
    border: 2px solid var(--true-black-color);
    border-radius: 16px;
    box-shadow: 3px 5px 0px 0px #1F1F23;
    background-color: white;
}

.Icon {
    pointer-events: none;
    margin-right: 8px;
    width: 24px;
}

.ButtonContentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Chooser:nth-child(1) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-right: 0px;
    margin-left: 12px;
    border-right: 0px;
}

.Chooser:nth-child(2) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.ChooserLeftActive {
    background-color:var(--soft-orange-color);
}

.ChooserRightActive {
    background-color: var(--purple-color);
}

.telegramButton {
    height: 48px;
    min-width: 80px;
    max-width: 80px;
    margin-right: 12px;
}

.telegramIcon {
    width: 22px;
    height: 20px;
    pointer-events: none;
}

.UpperButtonContainer {
    display: flex;
    width: 100%;
}
