.Popup {
    // justify-self: left;
    transition: all 0.5s ease-in-out;
    pointer-events: none; // через popup можно нажать!
    position: fixed;
    min-width: 85%;
    max-width: 85%;
    min-height: 62px;
    top: 10px;
    background-color: var(--soft-blue-color);
    border: 2px solid #1F1F23;
    border-radius: 24px;
    font: var(--font-m);
    font-weight: 700;
    z-index: 5;
    box-shadow: 3px 5px 0px var(--true-black-color);
    display: flex;
    align-items: center;
    opacity: 0;
}

.PopupFixed {
    margin-top: 10px;
    min-height: 62px;
    align-self: center;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    background: var(--soft-blue-color);
}

// .Popup:after,
// .Popup:before {
//    content: '';
//    border: solid transparent;
//    position: absolute;
// //    left: 50%;
//    margin-left: -16px;
//    bottom: 63px;
//    left: 50px;
//    width: 0;
//    transform: rotate(180deg);
// }
// .Popup:before {
//    border-top-color: var(--true-black-color);
//    border-width: 10px;
//    transform: rotate(180deg);

// }
// .Popup:after {
//    border-top-color: var(--soft-blue-color);
//    border-width: 10px;
//    margin-left: -16px;
//    margin-bottom: -2px;
// }

.Visible {
    opacity: 1;
}

.Text {
    margin: auto 10px auto 16px;
    width: 90%;
    font: var(--font-m);
    font-weight: 600;
}

.Whale {
    width: 42px;
    height: 42px;
    // margin-top: 10px;
    margin-left: 16px;
}

// .Triangle {
//     // top: -6px;
//     // left: 20px;
//     // transform: rotate(45deg);
//     // position: absolute;
//     // width: 12px;
//     // height: 12px;
//     // background-color:var(--soft-blue-color);
//     // border: 2px solid #1F1F23;
//     width: 0;
//     height: 0;
//     border: 10px solid transparent;
//     border-bottom-color: red;
// }

@media (min-width: 1023px) {
    .Popup {
        width: 680px;
        min-width: auto;
        max-width: auto;
    }
}