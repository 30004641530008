.normal {
    --true-black-color: #1f1f23;
    --sec-black-color: #44444d;
    --ter-black-color: #8f8f9c;
    --true-white-color: #ffffff;

    //soft
    --soft-gray-color: #f1f1f1;
    --soft-blue-color: #def3f8;
    --soft-yellow-color: #f8f6e9;
    --soft-pink-color: #fcd8d8;
    --soft-orange-color: #ffcb11;
    --soft-green-color: #ceff1a;

    //experimental
    --blue-color: #3a86e9;
    --aquamarine-color: #a9fcf7;
    --green-color: #5bc470;
    --orange-color: #ffa237;
    --red-color: #ec465c;
    --purple-color: #c6b9fe;

    //random
    --first-card-color: #ff8a8a;
    --second-card-color: #ff94b0;
    --third-card-color: #fe964c;
    --fourth-card-color: #feba33;
    --fifth-card-color: #f2e124;
    --sixth-card-color: #d9e82e;
    --seventh-card-color: #87d94c;
    --eighth-card-color: #6bd66b;
    --ninth-card-color: #5fcdd9;
    --tenth-card-color: #6fc7f2;
    --eleventh-card-color: #7dabf0;
    --twelfth-card-color: #a1a7ff;
    --thirteenth-card-color: #ccb2ff;
    --fourteenth-card-color: #eaabff;
}
