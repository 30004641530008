.Credits { 
    margin-top: 100px;
    display: flex;
    // margin-bottom: 16px;
    padding-bottom: 20px;
    width: 100%;
    justify-content: space-between;
}

.CreditsText {
    margin-left: 16px;
    margin-right: 16px;
}

.href {
    text-decoration: none;
    color: var(--blue-color);
}

.short {
    flex-direction: column;
    align-items: center;
}

.margin {
    padding-bottom: 100px;
}

.CreditsText:first-child {
    margin-right: 0px;
}

.CreditsText:nth-child(2) {
    width: 90px;
    margin-left: 0px;
}