.Container {
}

.text {
    margin: 0 0;
    color: var(--true-black-color);
}

.size_m {
    font: var(--font-m);
}

.size_l {
    font: var(--font-l);
}

.size_xl {
    font: var(--font-xl);
}

.size_s {
    font: var(--font-s);
}

.size_xs {
    font: var(--font-xs);
}

.size_xxs {
    font: var(--font-xxs);
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.black {
    color: var(--true-black-color);
}

.secblack {
    color: var(--sec-black-color);
}

.terblack {
    color: var(--ter-black-color);
}

.white {
    color: var(--true-white-color);
}

.red {
    color: var(--red-color);
}

.orangeGradient {
    color: transparent;
    background-clip: text;
    background-image: linear-gradient(90deg, #ffa237 0%, #ff7e34 100%);
}

.bold {
    font-weight: 700;
}

.sbold {
    font-weight: 600;
}

.med {
    font-weight: 500;
}

.reg {
    font-weight: 400;
}
