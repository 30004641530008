.Menu {
    position: absolute;
    // top: 55px;
    // right: 24px;
    // padding-top: 10px;s
    // position: absolute;
    width: 100%;
    height: 100%;
    // background-color: pink;
    z-index: 10;
}

.background {
    position: absolute;
    z-index: 9;
    top: -800px;
    width: 100vw;
    height: 1600px;
    // background-color: red;
}

.Wrapper {
    animation: 0.1s show linear;
    position: absolute;
    z-index: 11;
    top: 60px;
    right: 12px;
    width: 224px;
    // height: 150px;
    border: 2px solid var(--true-black-color);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--true-white-color);
}

.item {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.line {
    width: 100%;
    height: 2px;
    background-color: var(--soft-gray-color);
}

.lineBold {
    width: 100%;
    height: 6px;
    background-color: var(--soft-gray-color);
}


.icon {
    width: 24px;
    pointer-events: none;
}

.text {
    width: 74%;
}

@keyframes show {
    from { opacity: 0; transform: scale(0.8) }
    to { opacity: 1; transform: scale(1) }
}
