.InstallWidget {
    animation: 0.6s show ease;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.Text {
    margin-top: 32px;
    text-align: center;
    font: var(--font-xl);
    font-weight: 700;
}

.SecondText {
    font: var(--font-m);
    font-weight: 500;
    margin-top: 32px;
    text-align: center;
}

.ImageContainer {
    display: flex;
    width: 100px;
    height: 100px;
}

.Icon {
    position: absolute;
    width: 40px;
    height: 40px;
    margin-left: 60px;
    margin-top: 60px;
    // top: 2px;
    // left: 200px;
    // top: 320px
}

.Button {
    margin-top: 32px;
}

.Image {
    width: 100px;
}

.Cross {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 24px;
    top: 24px;
}

@keyframes show {
    from { opacity: 0; transform: translateY(30px) }
    to { opacity: 1; transform: translateY(0px) }
}