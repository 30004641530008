.Container {
    animation: 1s show ease;
    display: flex;
    width: 100vw;
    height: 100dvh;
    justify-content: center;
    flex-direction: column;
    // background-color: #E3F1FD;
    background-color: white;
}

.Icon {
    align-self: center;
    width: 120px;
    height: 120px;
}

.Text {
    margin-top: 10px;
    color: #44444D;
    text-align: center;
    font: var(--font-l);
    font-weight: 700;
}

.loader {
    margin-top: 0;
    left: calc(50% - 25px);
    top: 30%;
    filter: invert(28%) sepia(2%) saturate(3220%) hue-rotate(202deg) brightness(83%) contrast(88%);
}

@keyframes show {
    from { opacity: 0 }
    to { opacity: 1 }
}