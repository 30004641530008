.wrapper {
    animation: 0.6s show ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img {
    width: 220px;
    height: 160px;
}

.title {
    margin-top: 32px;
}

.text {
    margin: 16px 12px 0;
}

.button {
    margin-top: 32px;
}

@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
