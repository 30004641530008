:root {
    --font-family-main: 'Montserrat Alternates', sans-serif;

    --font-size-xl: 32px;
    --font-line-xl: 35.2px;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    --font-size-l: 24px;
    --font-line-l: 30px;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

    --font-size-m: 17px;
    --font-line-m: 21.25px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

    --font-size-s: 14px;
    --font-line-s: 17.5px;
    --font-s: var(--font-size-s) / var(--font-line-s) var(--font-family-main);

    --font-size-xs: 12px;
    --font-line-xs: 15px;
    --font-xs: var(--font-size-xs) / var(--font-line-xs) var(--font-family-main);

    --font-size-xss: 10px;
    --font-line-xss: 12.5px;
    --font-xss: var(--font-size-xss) / var(--font-line-xss) var(--font-family-main);

    --font-size-xxs: 9px;
    --font-line-xxs: 10.35px;
    --font-xxs: var(--font-size-xxs) / var(--font-line-xxs) var(--font-family-main);
}