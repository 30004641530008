.WantContainer {
    position: relative;
    width: 100%;
    // overflow: auto;
}

.Pinned {
    margin-left: 16px;
    width: 100%;
    margin-top: 32px;
}

.PinnedTextWrapper {
    display: flex;
    align-items: center;
}

.Icon {
    margin-left: 8px;
    width: 16px;
}

.CreditsShort { 
    margin-top: 193px;
}